import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import { ENV, Storage } from '@/utils';
import mutations from './mutations';
import getters from './getters';
import { userActions, projectActions, testingActions } from './actions/index';

const initialState = {
  appInfo: ENV.info, // 项目基本信息

  loading: true, // 全局loading状态
  responseLoading: false, // 等待响应 loading状态
  auth: false, // 登录验证状态
  userInfo: { // 用户信息
    detail: null,
    token: null,
  },
  permission: {
    editTeam: true,
    editProject: true,
  },
  theme: Storage.get(ENV.storage.theme) || {
    bg: 'light',
    brand: 'blue',
  },
  layoutType: 'normal', // 布局模式：normal、simple
  currentSliderNav: '', // 记录左侧边栏导航

  settingsManageModalVisible: false, // 管理设置弹框

  envManageModalVisible: false, // 管理环境弹框
  envManageModalValues: null,

  teamManageModalVisible: false, // 管理环境弹框
  teamManageModalValues: null,

  globalParamModalVisible: false,
  globalScriptModalVisible: false,
  globalVariableModalVisible: false,

  importProjectModalVisible: false, // 导入项目的弹框

  projectModalVisible: false, // 添加、编辑项目的弹框
  projectModalValues: null,
  
  categoryModalVisible: false, // 添加、编辑目录的弹框
  categoryModalValues: null,

  apiModalVisible: false, // 添加、编辑接口的弹框
  apiModalValues: null,
  
  filterKeyword: undefined, // 过滤关键词
  sliderTabKey: 'list', // 边栏tabs：history、list

  globalVariable: null, // 全局变量
  globalParam: null, // 全局参数
  envList: [], // 环境列表
  currentEnv: null, // 当前环境
  teams: [], // 团队
  currentTeam: null,
  projects: [], // 用户的项目列表
  currentProject: null,
  historyList: [], // 操作历史列表
  categories: [], // 目录列表 - 原始数据
  categoryTree: [], // 目录树 - 依据keyword过滤，作为边栏菜单显示使用
  apiTabs: [],
  currentApi: null,
  currentDoc: null,

  // 全局
  global: {

  },

  // 接口管理
  api: {

  },
  // 项目设置
  setting: {
    currentMenuKey: '1'
  },
  // 自动化测试
  testing: {
    currentMenuKey: '1',
    caseFilterKeyword: undefined,
    caseGroups: [], // 接口原数组
    caseGroupTree: [],
    currentTestingCase: null,
    addTestingCaseModalVisible: false,
    addTestingCaseModalValues: null,
    addTestingCaseGroupModalVisible: false,
    addTestingCaseGroupModalValues: null,
    // testingCaseStep: null, // 测试步骤 临时数据
    testingCaseEnv: null, // 测试用例环境
    testingCaseRoundNumber: 1, // 循环次数
    testingCaseThreadNumber: 1, // 线程数
    testingCaseIntervalNumber: 0, // 间隔停顿
    
    kitList: [],
    reportList: [],
  }
};

export default new Vuex.Store({
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions: {
    ...userActions, 
    ...projectActions,
    ...testingActions
  },
});
