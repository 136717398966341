const getters = {
  getUserInfo(state) {
    return state.userInfo;
  },
  getProjects(state) {
    return state.projects;
  },
  getProjectModalValues(state) {
    return state.projectModalValues;
  },
  getCategoryModalValues(state) {
    return state.categoryModalValues;
  },
  getApiModalValues(state) {
    return state.apiModalValues;
  },
  getCategoryList(state) {
    return state.categories;
  },
  getCategoryTree(state) {
    return state.categoryTree;
  },
  getApiTabs(state) {
    return state.apiTabs;
  },
}

export default getters;