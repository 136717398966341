import Request from '@/utils/request';

export const docApi = {
  searchUser: (params) => {
    return Request({
      url: '/user',
      method: 'get',
      params,
    });
  }
};

export default docApi;
