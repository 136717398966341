import Request from '@/utils/request';

export const testingApi = {
  saveTestingCase: (data) => {
    return Request({
      url: '/testing-case',
      method: 'post',
      data,
    });
  },

  deleteTestingCase: (id) => {
    return Request({
      url: `/testing-case/${id}`,
      method: 'delete'
    });
  },

  queryTestingCaseGroup: (params) => {
    return Request({
      url: '/testing-case-group',
      method: 'get',
      params,
    });
  },

  saveTestingCaseGroup: (data) => {
    return Request({
      url: '/testing-case-group',
      method: 'post',
      data,
    });
  },

  deleteTestingCaseGroup: (id) => {
    return Request({
      url: `/testing-case-group/${id}`,
      method: 'delete'
    });
  },

  queryTestingCaseDetail: (id) => {
    return Request({
      url: `/testing-case/${id}`,
      method: 'get',
    });
  },

  // testing-case-request
  sendTestingCaseRequest: (data) => {
    return Request({
      url: `testingCaseRequest`,
      method: 'post',
      data,
    });
  },
  getTestingCaseRequestList: (params) => {
    return Request({
      url: `testing-case-reports`,
      method: 'get',
      params
    });
  },
  getTestingCaseRequestDetail: (id) => {
    return Request({
      url: `testing-case-report/${id}`,
      method: 'get'
    });
  },
  deleteTestingCaseRequest: (id) => {
    return Request({
      url: `/testing-case-report/${id}`,
      method: 'delete'
    });
  },
};

export default testingApi;
