import { ENV, Storage, toTree, buildProjectInfo } from '@/utils';

const mutations = {
  changeLoading(state, value) {
    state.loading = value;
  },
  changeResponseLoading(state, value) {
    state.responseLoading = value;
  },
  changeLoginStatus(state, data) {
    state.auth = data.auth;
    state.userInfo = data.userInfo;
    state.currentProject = data.currentProject ? buildProjectInfo(data.currentProject) : null;
  },
  clearLoginStatus(state) {
    Storage.remove(ENV.storage.token);
    Storage.remove(ENV.storage.env);
    Storage.remove(ENV.storage.currentProject);
    Storage.remove(ENV.storage.currentEnv);
    Storage.remove(ENV.storage.currentTeam);
    state.auth = null;
    state.userInfo = {
      detail: null,
      token: null,
    };
    state.currentProject = null;
    state.currentEnv = null;
    state.currentTeam = null;
    state.projects = [];
    state.envList = [];
    state.teams = [];
  },
  setUserInfo(state, data) {
    state.userInfo = data;
  },
  updateUserInfo(state, value) {
    state.userInfo = {
      ...state.userInfo,
      ...value
    };
  },

  changeSettingsManageModalVisible(state, value) {
    state.settingsManageModalVisible = value;
  },
  changeEnvManageModalVisible(state, value) {
    state.envManageModalVisible = value;
  },
  changeEnvManageModalValues(state, values) {
    state.envManageModalValues = values;
  },
  changeTeamManageModalVisible(state, value) {
    state.teamManageModalVisible = value;
  },
  changeTeamManageModalValues(state, values) {
    state.teamManageModalValues = values;
  },

  changeGlobalParamModalVisible(state, value) {
    state.globalParamModalVisible = value;
  },
  changeGlobalScriptModalVisible(state, value) {
    state.globalScriptModalVisible = value;
  },
  changeGlobalVariableModalVisible(state, value) {
    state.globalVariableModalVisible = value;
  },
  
  changeImportProjectModalVisible(state, value) {
    state.importProjectModalVisible = value;
  },
  changeProjectModalVisible(state, value) {
    state.projectModalVisible = value;
  },
  changeProjectModalValues(state, values) {
    state.projectModalValues = values;
  },
  changeCategoryModalVisible(state, value) {
    state.categoryModalVisible = value;
  },
  changeCategoryModalValues(state, values) {
    state.categoryModalValues = values;
  },
  changeApiModalVisible(state, value) {
    state.apiModalVisible = value;
  },
  changeApiModalValues(state, values) {
    state.apiModalValues = values;
  },

  setFilterKeyword(state, value) {
    state.filterKeyword = value;
  },
  setSliderTabKey(state, key) {
    state.sliderTabKey = key;
  },
  setEnvList(state, envList) {
    let globalVariable = null;
    let globalParam = null;
    const envs = [];
    envList.forEach(env => {
      if (env.name === 'globalVariable') {
        globalVariable = env;
      }
      else if (env.name === 'globalParam') {
        globalParam = env;
      }
      else {
        envs.push(env);
      }
    });
    const currentEnv = Storage.get(ENV.storage.currentEnv) || envs[0];
    if (!currentEnv) {
      return;
    }
    if (currentEnv.content && typeof(currentEnv.content) === 'string') {
      currentEnv.content = JSON.parse(currentEnv.content);
    }
    state.globalVariable = globalVariable;
    state.globalParam = globalParam;
    state.envList = envs;
    state.currentEnv = currentEnv;
  },
  setCurrentEnv(state, data) {
    Storage.set(ENV.storage.currentEnv, data);
    state.currentEnv = data;
  },
  setTeams(state, teams) {
    let currentTeam = null;
    if (teams.length) {
      currentTeam = Storage.get(ENV.storage.currentTeam) || teams[0];
    }
    state.teams = teams;
    state.currentTeam = currentTeam;
  },
  setCurrentTeam(state, currentTeam) {
    Storage.set(ENV.storage.currentTeam, currentTeam);
    state.currentTeam = currentTeam;
  },
  setProjects(state, projects) {
    const currentProjectStorage = Storage.get(ENV.storage.currentProject);
    let currentProject = null;
    if (projects.length) {
      if (currentProjectStorage && projects.find(item => item.id === currentProjectStorage.id)) {
        currentProject = currentProjectStorage;
      } else {
        currentProject = projects[0];
      }
    }
    state.currentProject = currentProject ? buildProjectInfo(currentProject) : null;
    state.projects = projects;
  },
  setCurrentProject(state, currentProject) {
    Storage.set(ENV.storage.currentProject, currentProject);
    state.currentProject = currentProject ? buildProjectInfo(currentProject) : null;
  },
  setHistoryList(state, data) {
    state.historyList = data;
  },
  setCategoryList(state, categories) {
    const categoryTree = toTree(categories);
    state.categories = categories;
    state.categoryTree = categoryTree;
  },
  clearCategoryList(state) {
    state.categories = [];
    state.categoryTree = [];
  },
  setApiTabs(state, tabs) {
    Storage.set(ENV.storage.currentApiTabs, { userId: state.userInfo.detail.id, tabs });
    state.apiTabs = tabs;
  },
  setCurrentApi(state, api) {
    state.currentApi = api;
  },
  updateApiDetail(state, apiDetail) {
    let apiTabs = [...state.apiTabs];
    apiTabs = apiTabs.map(api => {
      if (api.id === apiDetail.id) {
        api = Object.assign(api, apiDetail);
        return api;
      }
      return api;
    });
    if (state.currentApi && state.currentApi.id === apiDetail.id) {
      state.currentApi = Object.assign(state.currentApi, apiDetail);
    }
    state.apiTabs = apiTabs;
  },
  setCurrentDoc(state, currentDoc) {
    Storage.set(ENV.storage.currentDoc, currentDoc);
    state.currentDoc = currentDoc;
  },
  setCurrentSliderNav(state, key) {
    Storage.set(ENV.storage.currentSliderNav, { userId: state.userInfo.detail.id, key });
    state.currentSliderNav = key;
  },
  setTheme(state, value) {
    state.theme = value;
  },
  setLayoutType(state, value) {
    state.layoutType = value;
  },
  setCurrentSettingMenu(state, key) {
    Storage.set(ENV.storage.currentSettingMenuKey, { userId: state.userInfo.detail.id, key });
    state.setting.currentMenuKey = key;
  },
  setCurrentTestingMenu(state, key) {
    Storage.set(ENV.storage.currentTestingMenuKey, { userId: state.userInfo.detail.id, key });
    state.testing.currentMenuKey = key;
  },

  // testing
  setTestingCaseGroup(state, groups) {
    const caseGroupTree = toTree(groups);
    state.testing.caseGroups = groups;
    state.testing.caseGroupTree = caseGroupTree;
  },
  setTestingCaseFilterKeyword(state, value) {
    state.testing.caseFilterKeyword = value;
  },
  setCurrentTestingCase(state, value) {
    state.testing.currentTestingCase = value;
  },
  setTestingCaseStep(state, value) {
    state.testing.testingCaseStep = value;
  },
  changeAddTestingCaseModalVisible(state, value) {
    state.testing.addTestingCaseModalVisible = value;
  },
  changeAddTestingCaseModalValues(state, values) {
    state.testing.addTestingCaseModalValues = values;
  },
  changeAddTestingCaseGroupModalVisible(state, value) {
    state.testing.addTestingCaseGroupModalVisible = value;
  },
  changeAddTestingCaseGroupModalValues(state, values) {
    state.testing.addTestingCaseGroupModalValues = values;
  },
  setTestingCaseEnv(state, value) {
    state.testing.testingCaseEnv = value;
  },
}

export default mutations;
