/**
 * 全局变量
 */
 'use strict';

 module.exports = {
 
   api_base: '/api/v1',
 
   api: {
     old: 'http://127.0.0.1:8080',  // express
     dev: 'http://127.0.0.1:7001', // egg
     pro: 'https://www.apimini.com',
   },
 
   info: {
     appname: 'ApiMini',
     hometitle: 'ApiMini',
     keywords: '',
     description: '',
     author: 'ApiMini(www.apimini.com)',
     verification: '',
 
     address: '',
     hotline: '',
     email: '',
     icp: 'ICP经营许可证 京B2-20160180',
     beian: '京ICP备2022000062号-8',
     company: '北京ApiMini科技有限公司',
     copyright: '©2015-2022 ApiMini All rights reserved.',
     slogan: '',
     web: 'www.apimini.com',
     worktime: '9:00-17:30',
     home: 'https://www.apimini.com/',
     help: 'https://doc.apimini.com/',
   },
 
   storage: {
     token: 'apiTool-token',
     lastTel: 'apiTool-lastTel',
     remenber: 'apiTool-remenber',
     routerHistory: 'apiTool-routerHistory',
     theme: 'apiTool-theme',
     currentMenu: 'apiTool-currentMenu',
     currentTeam: 'apiTool-currentTeam',
     currentEnv: 'apiTool-currentEnv',
     currentApiTabs: 'apiTool-currentApiTabs', // { userId: string, tabs: any[] }
     currentProject: 'apiTool-currentProject',
     currentSliderNav: 'apiTool-currentSliderNav', // 记录左侧边栏导航
     currentSettingMenuKey: 'apiTool-currentSettingMenuKey', // 记录设置菜单key
     currentTestingMenuKey: 'apiTool-currentTestingMenuKey', // 记录测试菜单key
     activeCaseGroupKey: 'apiTool-activeCaseGroupKey',
     activeKitGroupKey: 'apiTool-activeKitGroupKey',
     activeReportGroupKey: 'apiTool-activeReportGroupKey',
     userCenterTabKey: 'apiTool-userCenterTabKey',
     perPage: 'apiTool-perPage',
     activeCategoryKey: 'apiTool-activeCategoryKey',
     activeDocKey: 'apiTool-activeDocKey',
 
     wechatLoginState: 'apiTool-WechatLoginState', // 微信授权登录state
     weiboLoginState: 'apiTool-WeiboLoginState', // 微博授权登录state
     qqLoginState: 'apiTool-QqLoginState', // QQ授权登录state
 
     accountAuth: 'apiTool-accountAuth', // 帐号身份验证
     ossToken: 'apiTool-ossToken', // ossToken
 
     readModel: 'apiTool-readModel', // 阅读模式：white、black
 
   },
 
 };
 
 