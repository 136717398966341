import { ENV, Storage, replaceBracketStr } from '@/utils';
import { testingApi } from '@/api';
// import router from '@/router';

export const testingActions = {

  getTestingCaseGroup: async ({ commit, state }, projectInfo) => {
    commit('changeLoading', true);
    const currentProject = state.currentProject || projectInfo;
    if (!currentProject) {
      commit('setTestingCaseGroup', []);
      return;
    }
    const params = {
      project_id: currentProject.id,
    }
    return new Promise((resolve, reject) => {
      testingApi
        .queryTestingCaseGroup(params)
        .then((res) => {
          if (res.code === 0) {
            commit('setTestingCaseGroup', res.data);
            commit('changeLoading', false);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveTestingCase: async ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      testingApi
        .saveTestingCase(data)
        .then((res) => {
          if (res.code === 0) {
            commit('setCurrentTestingCase', data);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteTestingCase: async ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
      testingApi
        .deleteTestingCase(id)
        .then((res) => {
          if (res.code === 0) {
            if (state.testing.currentTistingCase && state.testing.currentTistingCase.id === id) {
              commit('setCurrentTestingCase', null);
            }
            dispatch('getTestingCaseGroup');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveTestingCaseGroup: async ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      testingApi
        .saveTestingCaseGroup(data)
        .then((res) => {
          if (res.code === 0) {
            commit('changeAddTestingCaseGroupModalVisible', false);
            dispatch('getTestingCaseGroup');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteTestingCaseGroup: async ({ dispatch }, id) => {
    return new Promise((resolve, reject) => {
      testingApi
        .deleteTestingCaseGroup(id)
        .then((res) => {
          if (res.code === 0) {
            dispatch('getTestingCaseGroup');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  queryTestingCaseDetail: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      testingApi
        .queryTestingCaseDetail(id)
        .then((res) => {
          if (res.code === 0) {
            commit('setCurrentTestingCase', res.data);
            // commit('setTestingCaseStep', res.data.step);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendTestingCaseRequestAction: async ({ dispatch }, argus) => {
    const { apiList, currentEnv } = argus;
    const requestList = [];
    apiList.forEach(apiDetail => {
      const requestUrl = replaceBracketStr(apiDetail.url, currentEnv);
      const headers = {}; // 和项目自身的接口区分
      const params = {};
      let data = {};
      if (apiDetail.auth) {
        switch(apiDetail.auth.type) {
          case AUTH.BEARER_TOKEN:
            const token = replaceBracketStr(apiDetail.auth.content.token, currentEnv);
            headers['Authorization'] = 'Bearer ' + token;
            break;
          case AUTH.BASIC_AUTH:
            const username = replaceBracketStr(apiDetail.auth.content.username, currentEnv);
            const password = replaceBracketStr(apiDetail.auth.content.password, currentEnv);
            headers['Authorization'] = 'Basic ' + Base64.encode(username + ":" + password);
            break;
          case AUTH.API_KEY:
            const key = replaceBracketStr(apiDetail.auth.content.key, currentEnv);
            const value = replaceBracketStr(apiDetail.auth.content.value, currentEnv);
            if (apiDetail.auth.content.in === 'header') {
              headers[key] = value;
            } else {
              params[key] = value;
            }
            break;
          default:
            break;
        }
      }
      // request headers
      if (typeof apiDetail.header_params === 'string') {
        apiDetail.header_params = JSON.parse(apiDetail.header_params);
      }
      apiDetail.header_params.forEach((item) => {
        if (item.key && item.value && !item.disabled) {
          headers[item.key] = item.value;
        }
      });
      // request query params
      if (typeof apiDetail.query_params === 'string') {
        apiDetail.query_params = JSON.parse(apiDetail.query_params);
      }
      if (apiDetail.query_params && apiDetail.query_params.length) {
        apiDetail.query_params.forEach((item) => {
          if (item.key && item.value) {
            params[item.key] = item.value;
          }
        });
      }
      // request body
      switch (apiDetail.body_type) {
        case 'formdata':
          if (apiDetail.body_params.length) {
            apiDetail.body_params.forEach((item) => {
              if (item.key && item.value) {
                data[item.key] = item.value;
              }
            });
          }
          break;
        case 'urlencoded':
          if (apiDetail.body_params.length) {
            apiDetail.body_params.forEach((item) => {
              if (item.key && item.value) {
                data[item.key] = item.value;
              }
            });
          }
          break;
        case 'raw':
          if (apiDetail.body_raw && apiDetail.raw_content_type === 'json') {
            data = JSON.parse(apiDetail.body_raw);
          } else {
            data = apiDetail.body_raw;
          }
          break;
        default:
          break;
      }
      const request = {
        url: requestUrl,
        name: apiDetail.name,
        method: apiDetail.method,
        headers,
        data,
        params,
      }
      requestList.push(request);
    });
    const res = await dispatch('sendTestingCaseRequest', {
      case_id: argus.case_id,
      requestList,
      testingCaseRoundNumber: argus.testingCaseRoundNumber,
      testingCaseThreadNumber: argus.testingCaseThreadNumber,
      testingCaseIntervalNumber: argus.testingCaseIntervalNumber
    });
    return res;
  },

  sendTestingCaseRequest: async (_, data) => {
    return new Promise((resolve, reject) => {
      testingApi
        .sendTestingCaseRequest(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  queryTestingCaseReportList: async (_, id) => {
    return new Promise((resolve, reject) => {
      testingApi
        .getTestingCaseRequestList(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  queryTestingCaseReportDetail: async (_, id) => {
    return new Promise((resolve, reject) => {
      testingApi
        .getTestingCaseRequestDetail(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteTestingCaseReport: async (_, id) => {
    return new Promise((resolve, reject) => {
      testingApi
        .deleteTestingCaseRequest(id)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default testingActions;
