import { Base64 } from 'js-base64';
import { ENV, Storage, replaceBracketStr } from '@/utils';
import { AUTH } from '@/utils/constant';
import { projectApi } from '@/api';
import { message, notification } from 'ant-design-vue';

export const projectActions = {
  showNotice: (_, { type, message, description }) => {
    const noticeType = type || 'success'
    notification[noticeType]({
      message,
      description,
    })
  },

  // 查询环境列表
  getEnvList: async ({ commit }) => {
    return new Promise((resolve, reject) => {
      projectApi
        .getEnvList()
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach(env => {
              env.content = env.content ? JSON.parse(env.content) : [];
              return env;
            });
            commit('setEnvList', res.data);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 添加、编辑环境
  saveEnv: async ({ dispatch, state, commit }, values) => {
    return new Promise((resolve, reject) => {
      projectApi
        .saveEnv(values)
        .then(async (res) => {
          if (res.code === 0) {
            if (state.currentEnv && state.currentEnv.id === values.id) {
              commit('setCurrentEnv', { ...values });
            } else {
              commit('setCurrentEnv', null);
            }
            message.success('保存成功！');
            await dispatch('getEnvList');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除环境
  deleteEnv: async ({ commit, state }, id) => {
    return new Promise((resolve, reject) => {
      projectApi
        .deleteEnv(id)
        .then((res) => {
          if (res.code === 0) {
            const newEnvList = state.envList.filter(env => env.id !== id);
            commit('setEnvList', newEnvList);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 查询teams
  getTeamList: async ({ commit, state }) => {
    const params = {
      ids: state.userInfo.detail ? state.userInfo.detail.teams : null
    }
    return new Promise((resolve, reject) => {
      projectApi
        .getTeamList(params)
        .then((res) => {
          if (res.code === 0) {
            commit('setTeams', res.data);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 添加team
  addTeam: async ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      projectApi
        .saveTeam(data)
        .then(async (res) => {
          if (res.code === 0) {
            await dispatch('tokenAuth')
            dispatch('getTeamList');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除team
  deleteTeam: async ({ dispatch }, teamId) => {
    return new Promise((resolve, reject) => {
      projectApi
        .deleteTeam(teamId)
        .then(async (res) => {
          if (res.code === 0) {
            dispatch('getTeamList');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 保存team
  saveTeam: async ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      projectApi
        .saveTeam(data)
        .then((res) => {
          if (res.code === 0) {
            dispatch('getTeamList');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 查询team members
  getTeamMembers: async ({ commit, state }, team_id) => {
    return new Promise((resolve, reject) => {
      projectApi
        .getTeamMembers(team_id)
        .then((res) => {
          if (res.code === 0) {
            resolve(res.data)
            // const currentTeam = state.currentTeam;
            // currentTeam.members = res.data;
            // commit('setCurrentTeam', currentTeam);
          } else {
            commit('setCurrentTeam', null);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 添加team members
  // addTeamMember: async (_, params) => {
  //   return new Promise((resolve, reject) => {
  //     projectApi
  //       .addTeamMember(params.team_id, params.data)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           resolve(res.data);
  //         } else {
  //           resolve([]);
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
  // 保存team member
  saveTeamMember: async (_, params) => {
    return new Promise((resolve, reject) => {
      projectApi
        .saveTeamMember(params.team_id, params.data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除team member
  deleteTeamMember: async (_, params) => {
    return new Promise((resolve, reject) => {
      projectApi
        .deleteTeamMember(params.team_id, params.member_id)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  inviteTeamMemberByEmail: async (_, params) => {
    return new Promise((resolve, reject) => {
      projectApi
        .inviteTeamMemberByEmail(params.teamId, params.data)
        .then((res) => {
          if (res.code === 0) {
            resolve(res.data);
            message.success('邀请邮件发送成功！');
          } else {
            resolve([]);
            message.error(res.message);
          }
        })
        .catch((error) => {
          reject(error);
          message.error(error.message);
        });
    });
  },

  queryProjects: async (_, params) => {
    return new Promise((resolve, reject) => {
      projectApi
        .getProjects(params)
        .then((res) => {
          if (res.code === 0) {
            resolve(res.data);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 查询项目列表
  getProjectList: async ({ commit, state }, params) => {
    const currentTeam = Storage.get(ENV.storage.currentTeam);
    const defaultParams = {
      team_id: currentTeam ? currentTeam.id : state.teams[0].id
    }
    const query = params || defaultParams;
    return new Promise((resolve, reject) => {
      projectApi
        .getProjects(query)
        .then((res) => {
          if (res.code === 0) {
            commit('setProjects', res.data);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 查询项目信息
  getProjectInfo: async({ commit }, project_id) => {
    return new Promise((resolve, reject) => {
      projectApi
        .getProject(project_id)
        .then((res) => {
          if (res.code === 0) {
            commit('setCurrentProject', res.data);
          } else {
            commit('setCurrentProject', null);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 添加项目
  addProject: async ({ commit }, values) => {
    return new Promise((resolve, reject) => {
      projectApi
        .saveProject(values)
        .then((res) => {
          if (res.code === 0) {
            commit('changeProjectModalVisible', false);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 删除项目
  deleteProject: async (_, id) => {
    return new Promise((resolve, reject) => {
      projectApi
        .deleteProject(id)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 编辑项目信息
  saveProjectInfo: async ({ commit, dispatch }, values) => {
    return new Promise((resolve, reject) => {
      projectApi
        .saveProject(values)
        .then((res) => {
          if (res.code === 0) {
            commit('changeProjectModalVisible', false);
            dispatch('getProjectList');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 导入项目
  saveImportProject: async ({ commit, dispatch }, values) => {
    return new Promise((resolve, reject) => {
      projectApi
        .importProject(values)
        .then((res) => {
          if (res.code === 0) {
            commit('changeProjectModalVisible', false);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 查询目录列表
  getCategoryList: async ({ commit, state }, projectInfo) => {
    const currentProject = state.currentProject || projectInfo;
    if (!currentProject) {
      commit('setCategoryList', []);
      return;
    }
    const params = {
      project_id: currentProject.id,
      version: currentProject.version,
    }
    return new Promise((resolve, reject) => {
      projectApi
        .getCategoryList(params)
        .then((res) => {
          if (res.code === 0) {
            commit('setCategoryList', res.data);
            // commit('setApiTabs', res.data[0].apiList); // mock
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 查询操作历史
  getHistoryList: async ({ commit, state }) => {
    const currentProject = state.currentProject;
    if (!currentProject) {
      return;
    }
    const params = {
      project_id: currentProject.id,
      version: currentProject.version,
    }
    return new Promise((resolve, reject) => {
      projectApi
        .getHistoryList(params)
        .then((res) => {
          if (res.code === 0) {
            commit('setHistoryList', res.data);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 添加、编辑目录
  saveCategory: async ({ commit, dispatch }, values) => {
    commit('changeLoading', true);
    return new Promise((resolve, reject) => {
      projectApi
        .saveCategory(values)
        .then((res) => {
          if (res.code === 0) {
            commit('changeCategoryModalVisible', false);
            dispatch('getCategoryList');
            commit('changeLoading', false);
          }
          resolve(res);
        })
        .catch((error) => {
          commit('changeLoading', false);
          reject(error);
        });
    });
  },

  // 删除目录
  deleteCategory: async ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      projectApi
        .deleteCategory(id)
        .then((res) => {
          if (res.code === 0) {
            dispatch('getCategoryList');
            // update activeCategoryKey localStorage
            const activeCategoryKey = Storage.get(ENV.storage.activeCategoryKey);
            if (activeCategoryKey && activeCategoryKey.includes(id)) {
              const newActiveCategoryKey = activeCategoryKey.filter(key => key !== id);
              Storage.set(ENV.storage.activeCategoryKey, newActiveCategoryKey);
            }
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 发送接口请求
  sendRequestAction: async ({ commit, dispatch, state }, apiDetail, selectedEnv) => {
    if (state.responseLoading) {
      return;
    }
    const currentEnv = selectedEnv || state.currentEnv;
    const requestUrl = replaceBracketStr(apiDetail.url, currentEnv);
    const headers = {}; // 和项目自身的接口区分
    const params = {};
    let data = {};
    if (!apiDetail.url) {
      message.error(`${apiDetail.name}：Request URL为空！`);
      return;
    }
    if (apiDetail.url === 'http://' || apiDetail.url === 'https://') {
      message.error('Request URL不完整！');
      return;
    }
    if (!requestUrl || !requestUrl.includes('http')) {
      message.error('Request域名为空，请检查设置！');
      return;
    }
    commit('changeResponseLoading', true);
    
    // request auth
    if (apiDetail.auth) {
      switch(apiDetail.auth.type) {
        case AUTH.BEARER_TOKEN:
          const token = replaceBracketStr(apiDetail.auth.content.token, currentEnv);
          headers['Authorization'] = 'Bearer ' + token;
          break;
        case AUTH.BASIC_AUTH:
          const username = replaceBracketStr(apiDetail.auth.content.username, currentEnv);
          const password = replaceBracketStr(apiDetail.auth.content.password, currentEnv);
          headers['Authorization'] = 'Basic ' + Base64.encode(username + ":" + password);
          break;
        case AUTH.API_KEY:
          const key = replaceBracketStr(apiDetail.auth.content.key, currentEnv);
          const value = replaceBracketStr(apiDetail.auth.content.value, currentEnv);
          if (apiDetail.auth.content.in === 'header') {
            headers[key] = value;
          } else {
            params[key] = value;
          }
          break;
        default:
          break;
      }
    }
    // request headers
    if (typeof apiDetail.header_params === 'string') {
      apiDetail.header_params = JSON.parse(apiDetail.header_params);
    }
    apiDetail.header_params.forEach((item) => {
      if (item.key && item.value && !item.disabled) {
        headers[item.key] = item.value;
      }
    });
    // request query params
    if (typeof apiDetail.query_params === 'string') {
      apiDetail.query_params = JSON.parse(apiDetail.query_params);
    }
    if (apiDetail.query_params && apiDetail.query_params.length) {
      apiDetail.query_params.forEach((item) => {
        if (item.key && item.value) {
          params[item.key] = item.value;
        }
      });
    }
    // request body
    switch (apiDetail.body_type) {
      case 'formdata':
        if (apiDetail.body_params.length) {
          apiDetail.body_params.forEach((item) => {
            if (item.key && item.value) {
              data[item.key] = item.value;
            }
          });
        }
        break;
      case 'urlencoded':
        if (apiDetail.body_params.length) {
          apiDetail.body_params.forEach((item) => {
            if (item.key && item.value) {
              data[item.key] = item.value;
            }
          });
        }
        break;
      case 'raw':
        if (apiDetail.body_raw && apiDetail.raw_content_type === 'json') {
          data = JSON.parse(apiDetail.body_raw);
        } else {
          data = apiDetail.body_raw;
        }
        break;
      default:
        break;
    }
    // 更新全局变量request
    request = {
      method: apiDetail.method,
      url: requestUrl,
      headers,
      data,
      params,
    }
    // send request
    const startTime = new Date();
    try {
      // Pre-request Scripts
      if (apiDetail.pre_request_raw) {
        try {
          eval(apiDetail.pre_request_raw);
          const localStore = JSON.parse(pm.environment.get('localStore'));
          if (localStore) {
            const regex = /\{\{(.+?)\}\}/g;
            for (let i in request.data) {
              for (let j in localStore) {
                if (request.data[i].match(regex) && request.data[i].includes(j)) {
                  request.data[i] = localStore[j];
                }
              }
            }
          }
        } catch (error) {
          console.error(error);
          message.error('pre_request_raw: ' + error.message);
          commit('changeResponseLoading', false);
        }
      }
      // 请求接口
      const res = await dispatch('sendRequest', request);
      pm.response = res.data;
      if (!res.data) {
        res.data = 'Invalid URI';
      }
      
      dispatch('updateDetailAction', { response: res, startTime, apiDetail });

      // After-request Scripts
      if (apiDetail.after_request_raw) {
        try {
          eval(apiDetail.after_request_raw);
        } catch (error) {
          console.error(error.message);
          message.error('after_request_raw: ' + error.message);
          commit('changeResponseLoading', false);
          dispatch('updateDetailAction', { response: res, startTime, apiDetail });
        }
      }
      return res;
    } catch(error) {
      console.error(error);
      dispatch('updateDetailAction', { response: error.response, startTime, apiDetail });
    }
    
  },

  updateDetailAction: async ({ commit }, { response, startTime, apiDetail }) => {
    const responseData = response ? { ...response } : {};
    let contentType = 'json';
    let contentSize = 0;
    let cookies = null;
    const headers = [];
    for (var key in responseData.headers) {
      if (typeof responseData.headers[key] === 'string') {
        headers.push({ id: key, key, value: responseData.headers[key] });
      }
      if (key === 'content-type') {
        contentType = responseData.headers[key].split(';')[0].split('/')[1];
      }
      if (key === 'content-length') {
        contentSize = (responseData.headers[key] / 1000).toFixed(2);
      }
      if (key === 'set-cookie') {
        cookies = responseData.headers[key];
        responseData.headers[key].forEach((cookie, index) => {
          headers.push({ id: `${key}-${index}`, key, value: cookie });
        });
      }
    }
    responseData.contentType = contentType;
    responseData.cookies = cookies;
    responseData.headers = headers;
    responseData.time = new Date() - startTime;
    responseData.size = contentSize; // getStringSize(dataRaw);
    responseData.dataRaw = responseData.data ? JSON.stringify(responseData.data, null, 2) : null;
    apiDetail.response = responseData;

    commit('updateApiDetail', apiDetail);
    commit('changeResponseLoading', false);
  },

  // 保存request 
  saveRequestAction: ({ commit, dispatch }, apiDetail) => {
    apiDetail.header_params = apiDetail.header_params
        ? apiDetail.header_params.filter((item) => item.key && item.value)
        : [];
      apiDetail.query_params = apiDetail.query_params
        ? apiDetail.query_params.filter((item) => item.key && item.value)
        : [];
      apiDetail.body_params = apiDetail.body_params
        ? apiDetail.body_params.filter((item) => item.key && item.value)
        : [];
      // apiDetail.action = apiDetail.id.includes('new') ? 'create' : 'edit';
      if (apiDetail.action === 'create') {
        if (apiDetail.response) {
          delete apiDetail.response;
        }
        commit('changeApiModalValues', apiDetail);
        commit('changeApiModalVisible', true);
      } else {
        apiDetail.header_params = apiDetail.header_params;
        apiDetail.query_params = apiDetail.query_params;
        apiDetail.body_params = apiDetail.body_params;
        if (apiDetail.response) {
          delete apiDetail.response;
        }
        delete apiDetail.create_time;
        delete apiDetail.update_time;
        delete apiDetail.update_by;
        delete apiDetail.current;
        delete apiDetail.saved;
        delete apiDetail.type;
        dispatch('saveApi', apiDetail);
      }
  },

  // 添加、编辑接口
  saveApi: async ({ commit, dispatch, state }, values) => {
    commit('changeLoading', true);
    return new Promise((resolve, reject) => {
      projectApi
        .saveApi(values)
        .then((res) => {
          if (res.code === 0) {
            commit('changeApiModalVisible', false);
            values.action = 'edit';
            values.saved = true;
            commit('updateApiDetail', values);
            commit('setApiTabs', state.apiTabs);
            commit('changeLoading', false);
            dispatch('getCategoryList');
            message.success('保存成功！');
          }
          resolve(res);
        })
        .catch((error) => {
          commit('changeLoading', false);
          reject(error);
        });
    });
  },

  // 删除接口
  deleteApi: async ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
      projectApi
        .deleteApi(id)
        .then((res) => {
          if (res.code === 0) {
            dispatch('getCategoryList');
            if (state.apiTabs.find(api => api.id === id)) {
              const newApiTabs = state.apiTabs.filter(api => api.id !== id);
              commit('setApiTabs', newApiTabs);
            }
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendRequest: async (_, data) => {
    return new Promise((resolve, reject) => {
      projectApi
        .sendRequest(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};

export default projectActions;
