import { ENV, Storage } from '@/utils';
import { docApi } from '@/api';
import router from '@/router';

export const userActions = {
  queryDocDetail: async (_, data) => {
    return new Promise((resolve, reject) => {
      docApi
        .sendEmailCode(data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default userActions;
