import ENV from './env';
import Request from './request';
import Storage from './storage';
import { Encrypt, Decrypt } from './crypto';
import moment from 'moment';

export { ENV, Request, Storage, Encrypt, Decrypt };

export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const toTree = (data) => {
  let result = [];
  if (!Array.isArray(data)) {
    return result;
  }
  data.forEach((item) => {
    delete item.children;
  });
  let map = {};
  data.forEach((item) => {
    map[item.id] = item;
  });
  data.forEach((item) => {
    let parent = map[item.parent_id];
    item['scopedSlots'] = { title: 'title' };
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      result.push(item);
    }
  });
  return result;
};

// 过滤多维对象数组，若存在则返回该内容所存在的所有层级的数据
export const filterApiTree = (treeData, keyword) => {
  if (!keyword) {
    return { tree: treeData, ids: [] };
  }
  const ids = [];
  const tree = JSON.parse(JSON.stringify(treeData));
  const filterObj = (item) => {
    if (item.name.includes(keyword)) {
      ids.push(item.id);
      return true;
    }
    if (item.apiList) {
      item.apiList = item.apiList.filter(
        (api) =>
          api.name.includes(keyword) || (api.url && api.url.includes(keyword))
      );
      if (item.apiList.length) {
        ids.push(item.id);
        return true;
      }
    }
    if (item.children) {
      item.children = item.children.filter((child) => {
        if (child.name.includes(keyword)) {
          ids.push(child.id);
          return true;
        }
        if (child.apiList) {
          return filterObj(child);
        }
      });
      if (item.children.length) {
        ids.push(item.id);
        return true;
      }
    }
  };
  const newTree = tree.filter((item) => filterObj(item));
  return { tree: newTree, ids };
};

// 过滤 测试用例分组
export const filterGroupTree = (treeData, keyword) => {
  if (!keyword) {
    return { tree: treeData, ids: [] };
  }
  const ids = [];
  const tree = JSON.parse(JSON.stringify(treeData));
  const filterObj = (item) => {
    if (item.name.includes(keyword)) {
      ids.push(item.id);
      return true;
    }
    if (item.caseList) {
      item.caseList = item.caseList.filter(
        (item) =>
          item.name.includes(keyword) ||
          (item.url && item.url.includes(keyword))
      );
      if (item.caseList.length) {
        ids.push(item.id);
        return true;
      }
    }
    if (item.children) {
      item.children = item.children.filter((child) => {
        if (child.name.includes(keyword)) {
          ids.push(child.id);
          return true;
        }
        if (child.caseList) {
          return filterObj(child);
        }
      });
      if (item.children.length) {
        ids.push(item.id);
        return true;
      }
    }
  };
  const newTree = tree.filter((item) => filterObj(item));
  return { tree: newTree, ids };
};

// 从currentEnv中查找数据，替换双花括号中的内容
export const replaceBracketStr = (text, currentEnv) => {
  if (!text || !currentEnv || !currentEnv.content) {
    return text;
  }
  let result = text;
  const regex = /\{\{(.+?)\}\}/g;
  let options = text.match(regex);
  if (!options) {
    return text;
  }
  options.forEach((option) => {
    const key = option.substring(2, option.length - 2);
    const content = currentEnv.content.find((item) => item.key === key);
    if (content) {
      result = result.replace(option, content.value);
    } else {
      result = text;
    }
  });
  return result;
};

// 计算数据大小
export const getStringSize = (text) => {
  let textlength = Buffer.byteLength(text, 'utf8'); //得到占多少 bytes
  let size = textlength / 1024; //得到字符串占多少 KB
  return size.toFixed(2);
};

export const dateFormat = (date, format = 'YYYY-MM-DD') => {
  return moment(date).format(format);
};

/**
 * 拆解url参数转换为对象
 * @returns {Object}
 */
export function getUrlParams() {
  // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
  let url = window.location.href.split('?')[1];
  if (!url) return false;
  let arr = url.split('&');
  let obj = {};

  // 将每一个数组元素以=分隔并赋给obj对象
  for (let i = 0; i < arr.length; i++) {
    let tmp_arr = arr[i].split('=');
    obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
  }
  return obj;
}

//判断是否为json字符串
export function isJSON(str) {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    var obj = JSON.parse(str);
    if (typeof obj == 'object' && obj) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export function buildProjectInfo(projectInfo) {
  const project = JSON.parse(JSON.stringify(projectInfo));
  if (!project) {
    return project;
  }
  if (project.auth && typeof project.auth === 'string') {
    project.auth = JSON.parse(project.auth);
  }
  if (project.pre_request_raw && !isJSON(project.pre_request_raw)) {
    project.pre_request_raw = JSON.parse(project.pre_request_raw);
  }
  if (project.after_request_raw && !isJSON(project.after_request_raw)) {
    project.after_request_raw = JSON.parse(project.after_request_raw);
  }
  if (project.variable && typeof project.variable === 'string') {
    project.variable = JSON.parse(project.variable);
  }
  return project;
}
