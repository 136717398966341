import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Base64 } from 'js-base64';
import { ENV, Storage } from '@/utils';

axios.interceptors.request.use(
  function(config) {
    config.headers['Q-CONTEXT-PATH'] = window.location.pathname;
    if (Storage.get(ENV.storage.token)) {
      config.headers['Authorization'] = 'Basic ' + Base64.encode(Storage.get(ENV.storage.token) + ':'); //读取本地token
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

// 添加响应拦截器
// axios.interceptors.response.use(function (response) {
//   // 对响应数据做点什么
//   return response;
// }, function (error) {
//   // 对响应错误做点什么
//   return Promise.reject(error);
// });

Vue.use(VueAxios, axios);
