import Vue from 'vue';
import less from 'less';
import App from './App.vue';
import store from './store';
import router from './router';
import debounce from './mixins/debounce';
import './plugins/antd-vue';
import './plugins/axios-vue';
import './index.scss';

import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.use(less);
Vue.use(require('vue-moment'));
Vue.use(mavonEditor);
Vue.mixin(debounce);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default app;
