export default {
  data() {
    return {
      debounceTimer: null,
    };
  },
  methods: {
    debounce(func, t) {
      const delay = t || 1000;
      const args = arguments;
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      let callNow = !this.debounceTimer; // 是否立即执行
      this.debounceTimer = setTimeout(() => {
        this.debounceTimer = null;
      }, delay);
      if (callNow && func) {
        func.apply(this, args);
      }
    }
  }
};
