import { ENV, Storage } from '@/utils';
import { userApi } from '@/api';
import router from '@/router';

export const userActions = {
  // token验证
  tokenAuth: async ({ commit }, token) => {
    return new Promise((resolve, reject) => {
      userApi
        .token()
        .then((res) => {
          if (res.code === 0) {
            commit('changeLoginStatus', {
              auth: true,
              userInfo: {
                detail: res.data,
                token,
              },
            });
            resolve(res.data);
          } else {
            router.push('/');
            Storage.remove(ENV.storage.token);
            commit('changeLoginStatus', {
              auth: false,
              userInfo: {
                detail: null,
                token: null,
              },
            });
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  login: async (_, values) => {
    return new Promise((resolve, reject) => {
      userApi
        .login(values)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  register: async ({ commit }, values) => {
    return new Promise((resolve, reject) => {
      userApi
        .register(values)
        .then((res) => {
          if (res.code === 0) {
            Storage.set(ENV.storage.token, res.data.token); //保存token
            commit('changeLoginStatus', {
              auth: true,
              userInfo: {
                detail: res.data.detail,
                token: res.data.token,
              },
            });
            router.push('/projects');
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout: ({ commit }) => {
    commit('clearLoginStatus');
    router.push('/');
  },

  // 精确查询用户
  searchUser: async ({ commit, state }, params) => {
    return new Promise((resolve, reject) => {
      userApi
        .searchUser(params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 批量查询用户
  searchUsers: async ({ commit, state }, params) => {
    return new Promise((resolve, reject) => {
      userApi
        .searchUsers(params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendEmailCode: async (_, data) => {
    return new Promise((resolve, reject) => {
      userApi
        .sendEmailCode(data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  queryInviteInfo: async (_, data) => {
    return new Promise((resolve, reject) => {
      userApi
        .queryInviteInfo(data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  joinTeam: async (_, data) => {
    return new Promise((resolve, reject) => {
      userApi
        .joinTeam(data)
        .then((res) => {
          if (res.code === 0) {
            router.push('/projects');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default userActions;
