import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routes = [
  {
    path: '/',
    name: 'app',
    component: () => import('@/layout/BaseLayout/index'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/pages/home/index.vue'),
      },
      {
        path: '/introduce',
        name: 'introduce',
        component: () => import('@/pages/introduce/index.vue'),
      },
      {
        path: '/enterprise',
        name: 'enterprise',
        component: () => import('@/pages/enterprise/index.vue'),
      },
      {
        path: '/download',
        name: 'download',
        component: () => import('@/pages/download/index.vue'),
      },
      {
        path: '/price',
        name: 'price',
        component: () => import('@/pages/price/index.vue'),
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('@/pages/help/index.vue'),
      },
      {
        path: '/discuss',
        name: 'discuss',
        component: () => import('@/pages/discuss/index.vue'),
      },
      {
        path: '/deploy',
        name: 'deploy',
        component: () => import('@/pages/deploy/index.vue'),
      },
      {
        path: '/plugin',
        name: 'plugin',
        component: () => import('@/pages/plugin/index.vue'),
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('@/pages/service/index.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/about/index.vue'),
      },
      {
        path: '/community',
        name: 'community',
        component: () => import('@/pages/community/index.vue'),
      },
    ],
  },
  {
    path: '/user',
    name: 'user',
    redirect: '/user/login',
    component: () => import('@/layout/UserLayout/index'),
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: () => import('@/pages/user/login.vue'),
      },
      {
        path: '/user/register',
        name: 'register',
        component: () => import('@/pages/user/register.vue'),
      },
      {
        path: '/user/join',
        name: 'join',
        component: () => import('@/pages/user/join.vue'),
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/users',
    component: () => import('@/layout/AdminLayout/index'),
    children: [
      {
        path: '/admin/users',
        name: 'users',
        component: () => import('@/layout/AdminLayout/pages/users-manage.vue'),
      },
      {
        path: '/admin/projects',
        name: 'projects',
        component: () => import('@/layout/AdminLayout/pages/projects-manage.vue'),
      },
    ]
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/layout/ProjectLayout/index')
  },
  {
    path: '/docs/:project_id/:api_id?',
    name: 'docs',
    component: () => import('@/layout/DocLayout/index'),
  },
  {
    path: '*',
    name: 'page404',
    component: () => import('@/pages/other/page404'),
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
