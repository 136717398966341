export const HEADER_KEYS = [
  'Accept',
  'Accept-Charset',
  'Accept-Encoding',
  'Accept-Language',
  'Access-Control-Request-Headers',
  'Access-Control-Request-Method',
  'Authorization',
  'Cache-Control',
  'Content-MD5',
  'Content-Length',
  'Content-Transfer-Encoding',
  'Content-Type',
  'Cookie',
  'Cookie 2',
  'Connection',
  'Date',
  'DNT',
  'Expect',
  'From',
  'Host',
  'If-Match',
  'If-Modified-Since',
  'If-None-Match',
  'If-Range',
  'If-Unmodified-Since',
  'Keep-Alive',
  'Max-Forwards',
  'Pragma',
  'Proxy-Authorization',
  'Range',
  'TE',
  'Trailer',
  'Transfer-Encoding',
  'Upgrade',
  'User-Agent',
  'Via',
  'Warning',
  'X-Do-Not-Track',
  'x-api-key',
  'X-Requested-With',
];

export const HEADER_VALUES = [
  'application/atom+xml',
  'application/ecmascript',
  'application/json',
  'application/vnd.api+json',
  'application/javascript',
  'application/octet+stream',
  'application/ogg',
  'application/pdf',
  'application/postscript',
  'application/rdf+xml',
  'application/rss+xml',
  'application/soap+xml',
  'application/font+woff',
  'application/x-yaml',
  'application/xhtml+xml',
  'application/xml',
  'application/xml+dtd',
  'application/xop+xml',
  'application/zip',
  'application/gzip',
  'application/graphql',
  'application/a-www-form-urlencoded',
  'audio/basic',
  'audio/L24',
  'audio/mp4',
  'audio/mpeg',
  'audio/ogg',
  'audio/vorbis',
  'audio/vnd.rn+realaudio',
  'audio/vnd.wave',
  'audio/webm',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'message/http',
  'message/imdn+xml',
  'message/partial',
  'message/rfc822',
  'message/mixed',
  'multipart/alternative',
  'multipart/related',
  'multipart/form-data',
  'multipart/signed',
  'multipart/encrypted',
  'text/plain',
  'text/vcard',
];

export const HEADER_PARAM_OPTIONS = [
  'Authorization',
  'Cache-Control',
  'Content-Type',
  'Content-Length',
  'Host',
  'User-Agent',
  'Accept',
  'Accept-Encoding',
  'Connection',
];

export const REQUEST_METHOD_OPTIONS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];

export const REQUEST_TYPE = {
  'formdata': 'multipart/form-data',
  'urlencoded': 'application/x-www-form-urlencoded',
  'text': 'text/plain',
  'javascript': 'application/javascript',
  'json': 'application/json', // 'application/json; charset=utf-8'
  'html': 'text/html',
  'xml': 'application/xml',
}

export const API_STATUS_ENUM = {
  DEVELOPMENT: '开发中',
  TESTING: '测试中',
  COMPLETED: '已完成',
  OBSOLETE: '已废弃',
}

export const API_STATUS_OPYIONS = [
  { label: '开发中', value: 'DEVELOPMENT', color: 'processing'},
  { label: '测试中', value: 'TESTING', color: 'warning'},
  { label: '已完成', value: 'COMPLETED', color: 'success'},
  { label: '已废弃', value: 'OBSOLETE', color: 'default'},
]

export const AUTH = {
  // INHERIT_AUTH: 'Inherit auth',
  NO_AUTH: 'no',
  BEARER_TOKEN: 'bearer',
  BASIC_AUTH: 'basic',
  API_KEY: 'apikey',
};

export const AUTH_OPTIONS = [
  // 'Inherit auth',
  { label: 'No Auth', value: 'no' },
  { label: 'Bearer Token', value: 'bearer' },
  { label: 'Basic Auth', value: 'basic' },
  { label: 'API key', value: 'apikey' },
];

export const HEADER_PARAMS = {
  GET: [
    {
      key: 'Content-Length',
      value: '<calculated when request is sent>',
      default: true,
      description: '',
      default: true,
      checked: true,
      disabled: true,
    },
    {
      key: 'Cache-Control',
      value: 'no-cache',
      description: '',
      default: true,
      checked: true,
      disabled: true,
    },
    {
      key: 'Content-Type',
      value: 'application/json',
      description: '',
      default: true,
      checked: true,
    },
    {
      key: 'Connection',
      value: 'keep-alive',
      default: true,
      description: '',
      checked: true,
    },
    {
      key: 'Accept-Encoding',
      value: 'gzip, deflate',
      description: '',
      default: true,
      checked: true,
    },
  ],
  POST: [
    {
      key: 'Content-Length',
      value: '<calculated when request is sent>',
      default: true,
      description: '',
      default: true,
      checked: true,
      disabled: true,
    },
    {
      key: 'Cache-Control',
      value: 'no-cache',
      description: '',
      default: true,
      checked: true,
      disabled: true,
    },
    {
      key: 'Content-Type',
      value: 'application/json',
      description: '',
      default: true,
      checked: true,
    },
    {
      key: 'Connection',
      value: 'keep-alive',
      default: true,
      description: '',
      checked: true,
    },
    {
      key: 'Accept-Encoding',
      value: 'gzip, deflate',
      description: '',
      default: true,
      checked: true,
    },
  ],
};

export const TEAM_MEMBER_ROLE = {
  owner: '所有者',
  admin: '管理员',
  user: '普通成员',
  outsource: '外包人员',
};

export const TEAM_MEMBER_ROLE_OPTIONS = [
  { value: 'owner', label: '所有者' },
  { value: 'admin', label: '管理员' },
  { value: 'user', label: '普通成员' },
  { value: 'outsource', label: '外包人员' },
];

export const TEAM_MEMBER_STATUS = {
  PENDING: '等待审批',
  APPROVED: '已通过',
  REJECTED: '已拒绝'
};

export const TEAM_MEMBER_STATUS_OPTIONS = [
  { value: 'PENDING', label: '等待审批' },
  { value: 'APPROVED', label: '通过' },
  { value: 'REJECTED', label: '拒绝' },
]

export const PROJECT_MEMBER_ROLE_OPTIONS = [
  { value: 'owner', label: '所有者' },
  { value: 'admin', label: '管理员' },
  { value: 'user', label: '普通成员' },
  { value: 'readonly', label: '只读成员' },
  { value: 'guest', label: '访客' },
  { value: 'disabled', label: '禁止访问' },
];

export const REQUEST_CODE_MENU = [
  { language: 'cURL', type: '', value: 'shell:curl' },
  { language: 'C', type: 'libcurl', value: 'c:libcurl' },
  { language: 'Clojure', type: 'clj-http', value: 'clojure:clj_http' },
  { language: 'C#', type: 'RestSharp', value: 'csharp:restsharp' },
  { language: 'Go', type: 'NewRequest', value: 'go:native' },
  { language: 'HTTP', type: '', value: 'http:1.1' },
  { language: 'Java', type: 'OkHttp', value: 'java:okhttp' },
  { language: 'Java', type: 'Unirest', value: 'java:unirest' },
  { language: 'JavaScript', type: 'jQuery', value: 'javascript:jquery' },
  { language: 'JavaScript', type: 'fetch', value: 'javascript:fetch' },
  { language: 'JavaScript', type: 'XMLHttpRequest', value: 'javascript:xhr' },
  { language: 'Node', type: 'native', value: 'node:native' },
  { language: 'Node', type: 'request', value: 'node:request' },
  { language: 'Node', type: 'unirest', value: 'node:unirest' },
  { language: 'Objective-C', type: 'NSURLSession', value: 'objc:nsurlsession' },
  { language: 'OCaml', type: 'CoHTTP', value: 'ocaml:cohttp' },
  { language: 'PHP', type: 'cURL', value: 'php:curl' },
  { language: 'PHP', type: 'HTTP v1', value: 'php:http1' },
  { language: 'PHP', type: 'HTTP v2', value: 'php:http2' },
  { language: 'powershell', type: 'WebRequest', value: 'powershell:webrequest' },
  { language: 'powershell', type: 'RestMethod', value: 'powershell:restmethod' },
  { language: 'Python', type: 'http.client', value: 'python:python3' },
  { language: 'Python', type: 'Requests', value: 'python:requests' },
  { language: 'Ruby', type: 'net::http', value: 'ruby:native' },
  { language: 'Swift', type: '', value: 'swift:nsurlsession' },
];
